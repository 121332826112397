import $ from 'wee-dom';
import $router from 'wee-routes';
import './bootstrap';

/**
 * Dynamically load a component
 * @param {String} component
 */
const load = component => import(/* webpackChunkName: "[request]" */ `../components/${component}`)
    .then(m => m.default || m);

const controllers = {
    alerts: () => load('alerts'),
    alternatingVideoCta: () => load('blocks/alternating-video-cta'),
    baconPay: () => load('bacon-pay'),
    blogSlider: () => load('suggested'),
    branchDetails: () => load('blocks/branch-details-block'),
    carousel: () => load('carousel'),
    csrf: () => load('csrf'),
    productFaq: () => load('product-faq'),
    expandable: () => load('expandable'),
    features: () => load('blocks/features'),
    filter: () => load('filter'),
    footer: () => load('footer'),
    fullPageEarningsCalc: () => load('blocks/full-page-earnings-calc'),
    gliaButton: () => load('glia-button'),
    header: () => load('header'),
    helpPage: () => load('help'),
    heroSlider: () => load('hero/hero-slider'),
    history: () => load('history'),
    homeCalc: () => load('home-calculator'),
    locator: () => load('blocks/locator'),
    loginForm: () => load('login-form'),
    rateDashboard: () => load('rate-dashboard'),
    section: () => load('blocks/section'),
    sectionNav: () => load('section-nav'),
    offsiteLinks: () => load('offsite-links'),
    video: () => load('video'),
};
const $controllerEls = $('.js-controller');
const common = [
    controllers.offsiteLinks,
    controllers.csrf,
];

$controllerEls.each((el) => {
    if (Object.prototype.hasOwnProperty.call(controllers, $(el).data('controller'))) {
        const addController = controllers[$(el).data('controller')];

        if (common.indexOf(addController) === -1) {
            common.push(addController);
        }
    } else {
        console.warn('Missing component ' + $(el).data('controller')); // eslint-disable-line no-console
    }
});

$router.map([
    {
        path: '/about-us/history',
        handler: [
            ...common,
            controllers.history,
        ],
    },
    {
        path: '/branches/*',
        handler: [
            ...common,
            controllers.offsiteLinks,
            controllers.carousel,
        ],
    },
    {
        path: '/locations/*',
        handler: [
            ...common,
            controllers.offsiteLinks,
            controllers.carousel,
        ],
    },
    {
        path: '/virtual-appointment',
        handler: [
            ...common,
            controllers.gliaButton,
        ],
    },
    {
        path: '*',
        handler: [
            ...common,
            controllers.video,
        ],
    },
])
    .notFound({ handler: [...common] })
    .run();
