import { $setVar } from 'wee-store';
import { $setRef } from 'core/dom';
import { $chain } from 'core/chain';
import 'lazysizes';
import 'es6-promise/auto';
import 'object-fit-polyfill';
import 'picturefill';
import '../styles/global.scss';

// Import all component scss files
require.context('../components', true, /\.scss$/);

$setRef();
$setVar();

$chain({
    /**
     * Set an aria attribute and value
     *
     * @param {string} attr - valid aria attribute
     * @param {string|boolean} [value] - valid aria attribute value
     * @return {this}
     */
    aria(attr, value = null) {
        this.attr(`aria-${attr}`, value);

        return this;
    },

    /**
     * Set the aria attribute 'expanded'
     *
     * @param {boolean} [expand]
     * @returns {this}
     */
    ariaExpand(expand = true) {
        this.aria('expanded', expand);

        return this;
    },

    /**
     * Set the aria attribute 'hidden'
     *
     * @param {boolean} [hidden]
     * @return {this}
     */
    ariaHidden(hidden = true) {
        this.aria('hidden', hidden);

        return this;
    },
});
